import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {encryptData, serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import ManageUserForm from "./manage-user-form";

function ManageUsers(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "Username", "Role", "Status", "Action"];
    const [formData, setFormData] = useState({
        UserID: "",
        Username: "",
        Role: "",
        Status: "",
    })
    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}baze/partner/users/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Username}</td>
                        <td className="text-xs font-weight-bold">{item.Role === "1" ? "Admin" : "User"}</td>
                        <td className="text-xs font-weight-bold">{item.Status === "1" ? "Active":"In-Active"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       UserID: item.UserID,
                                       Username: item.Username,
                                       Role: item.Role,
                                       Password: item.Password,
                                       Status: item.Status,
                                       InsertedBy: item.InsertedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Username.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Username", "error");
            return false;
        }

        if (formData.Role.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Role", "error");
            return false;
        }

        if (formData.Status.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Status", "error");
            return false;
        }

        if (formData.UserID === "") {
            let sendData = {
                ...formData,
                Password: encryptData('123456789'),
                InsertedBy: `${props.loginData?.Username}`,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}baze/partner/user/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            UserID: "",
                            Username: "",
                            Password: "",
                            Role: "",
                            Status: "",
                        });
                    }  else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("warning", "Record with this NIN or Phone already exist", "warning")
            } else {
                showAlert("error", "Network error, please check your connection", "error")
            }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            setIsFormLoading(true);
            let sendData = {
                ...formData,
                Password: encryptData('123456789'),
                InsertedBy: `${props.loginData?.Username}`,
            }
            await axios
                .patch(`${serverLink}baze/partner/user/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            UserID: "",
                            Username: "",
                            Password: "",
                            Role: "",
                            Status: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Users
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               UserID: "",
                                               Username: "",
                                               Password: "",
                                               Role: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add User
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">User(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Partner(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageUserForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageUsers);
