import React, {useState, useRef, useEffect} from 'react';
import Webcam from 'react-webcam';
import {toast} from "react-toastify";

const ImageUploader = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isWebcamOpen, setIsWebcamOpen] = useState(false);
    const webcamRef = useRef(null);

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const image = event.target.files[0];
            if (event.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            } else {
                props.setFormData({
                    ...props.formData,
                    Passport: event.target.files[0]
                })
            }
            setSelectedImage(URL.createObjectURL(image));
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    };

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const imageFile = dataURLtoFile(imageSrc, 'webcam-image.jpg');

        if (imageFile.size > 1000000) {
            toast.error("max file size is 1mb")
            return false;
        } else {
            props.setFormData({
                ...props.formData,
                Passport: imageFile
            })
        }

        setSelectedImage(URL.createObjectURL(imageFile));
        setIsWebcamOpen(false);
    };

    useEffect(() => {
        setIsWebcamOpen(false)
        setSelectedImage(null)
    }, []);

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2>Upload Image</h2>
            <div style={{ marginBottom: '20px' }}>
                <button className={ isWebcamOpen ? 'btn btn-danger form-control' : 'btn btn-info form-control'} onClick={() => { setIsWebcamOpen(!isWebcamOpen); setSelectedImage(null); }}>
                    {isWebcamOpen ? 'Close Webcam' : 'Use Webcam'}
                </button>
            </div>
            {isWebcamOpen && (
                <div>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        height="auto"
                    />
                    <button onClick={handleCapture} style={{ marginTop: '10px' }}>
                        Capture Photo
                    </button>
                </div>
            )}
            {
                !isWebcamOpen ?
                    <div className="text-left" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <label htmlFor="file" className="col-md-4 col-lg-3 col-form-label">Passport </label>
                        (<small className="text-danger">Max size is 1mb</small>)
                        <input type="file"  accept="image/*" className="form-control" onChange={handleImageChange} />
                    </div>
                    : <></>
            }

            {selectedImage && (
                <div>
                    <h3>Preview:</h3>
                    <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageUploader;
