import React from "react";
import JoditEditor from "jodit-react";
import {formatDate} from "../../resources/constants";
export default function ManageContractsStaffForm(props) {
    const editorConfig = {
        readonly: false,
        height: 200
    };
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Assign Contract to Staff</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Partner</label>
                                <select className="form-control" name="PartnerID" id="PartnerID" value={props.formData.PartnerID} onChange={props.onEdit2}>
                                    <option value="">Select Partner</option>
                                    {
                                        props.partnerList.length > 0 &&
                                        props.partnerList.map((x, i) => {
                                            return <option key={i} value={x.PartnerID}>{x.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Staff</label>
                                <select className="form-control" name="StaffID" id="StaffID" value={props.formData.StaffID} onChange={props.onEdit}>
                                    <option value="">Select Staff</option>
                                    {
                                        props.filteredStaffList.length > 0 &&
                                        props.filteredStaffList.map((x, i) => {
                                            return <option key={i} value={x.StaffID}>{x.FirstName} {x.MiddleName} {x.Surname}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Contract</label>
                                <select className="form-control" name="ContractID" id="ContractID" value={props.formData.ContractID} onChange={props.onEdit}>
                                    <option value="">Select Contract</option>
                                    {
                                        props.filteredContractList.length > 0 &&
                                        props.filteredContractList.map((x, i) => {
                                            return <option key={i} value={x.ContractID}>{x.Title}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    name="StartDate"
                                    className="form-control"
                                    id="StartDate"
                                    value={formatDate(props.formData.StartDate)}
                                    onChange={props.onEdit}
                                    placeholder="StartDate"
                                />
                            </div>

                            <div className="mb-4 form-group col-md-6">
                                <label className="form-label">End Date</label>
                                <input
                                    type="date"
                                    name="EndDate"
                                    className="form-control"
                                    id="EndDate"
                                    value={formatDate(props.formData.EndDate)}
                                    onChange={props.onEdit}
                                    placeholder="EndDate"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <select name="Status" id="Status" className="form-control" value={props.formData.Status} onChange={props.onEdit}>
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">InActive</option>
                                </select>
                            </div>
                        </div>


                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}