export const setgeneralDetails = (p) => {
    return {
        type: "SET_GENERALDETAILS_DETAILS",
        payload: p,
    };
};

export const setPermissionDetails = (p) => {
    return {
        type: "SET_PERMISSION_DATA",
        payload: p,
    };
};

export const setLoginDetails = (p) => {
    return {
        type: "SET_LOGIN_DETAILS",
        payload: p,
    };
};


export const setFarmersData = (p) => {
    return {
        type: "SET_FARMERS_DATA",
        payload: p,
    };
};

export const setFarmerInfo = (p) => {
    return {
        type: "SET_FARMER_INFO",
        payload: p,
    };
};

export const setAgentData = (p) => {
    return {
        type: "SET_AGENT_DATA",
        payload: p,
    };
};