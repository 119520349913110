const CryptoJS = require("crypto-js");

export const status = "Live";
export const serverLink =  status === "Dev" ?  "http://localhost:4480/" : "https://api.bazeuniversity.edu.ng:5001/";

export const projectURL = "https://bazeuniversity.edu.ng/";
export const projectPhone = " +234-8063017470";
export const projectEmail = "info@bazeuniversity.edu.ng";

export const paymentAPIKey = status === "Dev" ? "pk_test_4afdc4ff65ce2328e5dd6a678f78103e0fe32c42" :  "pk_test_6c46f21ff1e7ec6e08b447d96c47e1872f88291a" ;

export function encryptData(string, val = false) {
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
}

export function decryptData(string, val = false) {
    let secret_key = val === false ? 'SayyoFilms' : 'BazeEncrypt';
    let secret_iv = val === false ? 'FilmsInternational' : 'BazeEncrypt';
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}
