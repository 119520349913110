import "./id-card.css";

function IDCARD() {
  return (
    <div className="parent">
      <div className="app-container">
        <div className="main-content">
          <div className="sidebar">
            <img src="./side_bar.png" alt="siderbar" />
          </div>
          <div className="content">
            <div className="background">
              <img src="./background.png" alt="background" />
            </div>
            <div className=" foreground">
              <div className="foreground-content">
                <h1 className="foreground-title">W'3 SAUCE</h1>
                <div className="foreground-id-card">
                  <img src="./id.jpeg" alt="backgorund " />
                </div>
                <div className="foreground-info">
                  <div className="foreground-info-item">
                    <h1>ALI DAUDA</h1>
                    <h2>Web Developer</h2>
                  </div>

                  <div className="foreground-info-item">
                    <h3>
                      <span>COMPANY:</span>W'3 SAUCE
                    </h3>
                    <h3>
                      <span>STAFFID:</span>W3001
                    </h3>
                    <h2>EXPIRES ON: 22/12/2022</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back-id">
        {" "}
        <img src="back-id.png" alt="backgorund " />
      </div>
    </div>
  );
}

export default IDCARD;
