export const user_type_chart_data = (data_) => {
    let t_counts = [];
    data_.length > 0 &&
    data_.map((x, y) => {
        t_counts.push(x.UserTypeCount)
    })
    let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

    let labels = [];
    data_.length > 0 &&
    data_.map((x, y) => {
        labels.push(x.UserType)
    })
    let t_labels_sorted = labels.sort(function (a, b) { return b - a; });

    const data = {
        series: t_counts_sorted,
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: t_labels_sorted,
            fill: {
                type: 'gradient',
            },
            title: {
                text: ''
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

    }
    return data;
}
export const status_type_chart_data = (data_) => {
    let t_counts = [];
    data_.length > 0 &&
    data_.map((x, y) => {
        t_counts.push(x.StatusTypeCount)
    })
    let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

    let labels = [];
    data_.length > 0 &&
    data_.map((x, y) => {
        labels.push(x.StatusType)
    })
    let t_labels_sorted = labels.sort(function (a, b) { return b - a; });

    const data = {
        series: t_counts_sorted,
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: t_labels_sorted,
            fill: {
                type: 'gradient',
            },
            title: {
                text: ''
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

    }
    return data;
}

