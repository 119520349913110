import React from "react";
import JoditEditor from "jodit-react";
import {formatDate} from "../../resources/constants";
export default function ManagePartnersForm(props) {
    const editorConfig = {
        readonly: false,
        height: 200
    };

    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Partners Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Partner Name</label>
                                <input
                                    name="Name"
                                    className="form-control"
                                    id="Name"
                                    value={props.formData.Name}
                                    onChange={props.onEdit}
                                    placeholder="Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">EmailAddress</label>
                                <input
                                    type="text"
                                    name="EmailAddress"
                                    className="form-control"
                                    id="EmailAddress"
                                    value={props.formData.EmailAddress}
                                    onChange={props.onEdit}
                                    placeholder="EmailAddress"
                                />
                                {props.error === "invalid" && (
                                    <span style={{ backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px' }}>
                                        {props.error && (
                                            <>
                                                {props.error === "invalid" && "Email is not valid"}
                                            </>
                                        )}
                                    </span>
                                )}
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">PhoneNumber</label>
                                <input
                                    type="text"
                                    name="PhoneNumber"
                                    className="form-control"
                                    id="PhoneNumber"
                                    value={props.formData.PhoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="PhoneNumber"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">CAC No</label>
                                <input
                                    type="text"
                                    name="CAC"
                                    className="form-control"
                                    id="CAC"
                                    value={props.formData.CAC}
                                    onChange={props.onEdit}
                                    placeholder="CAC"
                                />
                            </div>
                        </div>

                        <div className="mb-3 col-md-12 form-group">
                            <label className="form-label">Address </label>
                            <JoditEditor
                                value={props.formData.Address}
                                config={editorConfig}
                                onBlur={props.descriptionHandler}
                                onChange={(newContent) => {}}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
