import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import {formatDate, formatDateAndTime} from "../../resources/constants";
import ManageContractsForm from "./manage-contracts-form";

function ManageContracts(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "PartnerID","Title", "Location", "StartDate", "EndDate",  "Action"];
    const [formData, setFormData] = useState({
        ContractID: "",
        PartnerID: "",
        Location: "",
        Title: "",
        StartDate: "",
        EndDate: "",
    })
    const [partnerList, setPartnerList] = useState([]);


    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {

        await axios.get(`${serverLink}baze/partner/partners/all`).then((res) => {
            if (res.data.length > 0) {
                setPartnerList(res.data)
            }
        })
        await axios.get(`${serverLink}baze/partner/contracts/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PartnerName}</td>
                        <td className="text-xs font-weight-bold">{item.Title}</td>
                        <td className="text-xs font-weight-bold">{item.Location}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.StartDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.EndDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       ContractID: item.ContractID,
                                       PartnerID: item.PartnerID,
                                       Title: item.Title,
                                       Location: item.Location,
                                       StartDate: item.StartDate,
                                       EndDate: item.EndDate,
                                       InsertedBy: item.InsertedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter contract title", "error");
            return false;
        }

        if (formData.PartnerID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select partner", "error");
            return false;
        }

        if (formData.Location.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter contract location", "error");
            return false;
        }


        if (formData.StartDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select start date", "error");
            return false;
        }

        if (formData.EndDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select end date", "error");
            return false;
        }

        if (formData.ContractID === "") {
            let sendData = {
                ...formData,
                InsertedBy: `${props.loginData?.Username}`
            }

            setIsFormLoading(true);
            await axios
                .post(`${serverLink}baze/partner/contract/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Contract Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            ContractID: "",
                            PartnerID: "",
                            Title: "",
                            Location: "",
                            StartDate: "",
                            EndDate: "",
                            InsertedBy: "",
                            InsertedDate: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                StartDate: formatDate(formData.StartDate),
                EndDate: formatDate(formData.EndDate)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}baze/partner/contract/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Contract Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            ContractID: "",
                            PartnerID: "",
                            Title: "",
                            Location: "",
                            StartDate: "",
                            EndDate: "",
                            InsertedBy: "",
                            InsertedDate: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Contract Management
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               ContractID: "",
                                               PartnerID: "",
                                               Location: "",
                                               Title: "",
                                               StartDate: "",
                                               EndDate: "",
                                               InsertedBy: "",
                                               InsertedDate: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Contract
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Contract List</h3>
                                                <DataTable header={header} body={showTable()} title="Contract List" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageContractsForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    partnerList={partnerList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageContracts);
