import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../common/footer/footer";
import PageNotFound from "../404/page-not-found";
import { browserName, isMobile } from 'react-device-detect';
import axios from "axios";
import {serverLink} from "../../resources/url";

import DashboardRoot from "../dashboard/dashboard-root";
import Header from "../common/header/header";
import {Logout} from "@mui/icons-material";
import StaffProfile from "../user/account/staff-profile";
import ChatComponent from "../dashboard/testing";
import ChatBot from "../chat/chat";
import ManageItems from "../manage-items/manage-items";
import Items from "../items/items";
import ItemDetails from "../items/items-details";
import MyPayments from "../payment/payment";
import AllPaymentReport from "../report/payment-report-all";
import PaidNotCollected from "../report/paid-not-collected";
import PaidAndCollected from "../report/paid-and-collected";
import ApproveApplication from "../report/approve-application";
import ManagePartners from "../manage-partners/manage-partners";
import ManagePartnersStaff from "../partner-staff/manage-partner-staff";
import ManageUsers from "../manage-user/manage-user";
import ManageContracts from "../manage-contracts/manage-contracts";
import ManageContractsStaff from "../contracts-staff/manage-contracts-staff";
import IDCARD from "../id-card/id-card";

export default function PageRoutes() {
    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then(async (result)=> {
            await getVisitorData(result.data);
        }).catch((e)=>{
            console.log("Error Getting User Details")
        })

    }

    const getVisitorData = async (locData) => {
        let sendData = {
            IPAddress: locData.IPv4,
            Country: locData.country_name,
            lat: locData.latitude,
            lng: locData.longitude,
            IsMobileRequest: isMobile ? "1" : "0",
            UserAgent: browserName,
        }

        await axios.post(`${serverLink}general/website/visit-hit`, sendData).then(data => {

        }).catch(err => {
            console.log("Error Adding User Details")
        });
    };

    useEffect(() => {
        // getLocationData();
    }, []);

    return (<>
        <div className="wrapper">
            <Header/>
            <div className="page-wrapper">
                <Routes>
                    {/* Dashboard Redirect */}
                    <Route exact path="/" element={<DashboardRoot />} />
                    <Route path="/tbl" element={<ChatComponent/>} />
                    <Route path="/chat" element={<ChatBot/>} />
                    <Route path="/manage-items" element={<ManageItems/>} />
                    <Route path="/manage-partners" element={<ManagePartners/>} />
                    <Route path="/manage-partners-staff" element={<ManagePartnersStaff/>} />
                    <Route path="/manage-users" element={<ManageUsers/>} />
                    <Route path="/items" element={<Items/>} />
                    <Route path="/item/details/:slug" element={<ItemDetails/>} />
                    <Route path="/payments" element={<MyPayments/>} />
                    <Route path="/payment-report-all" element={<AllPaymentReport/>} />
                    <Route path="/paid-not-collected" element={<PaidNotCollected/>} />
                    <Route path="/paid-and-collected" element={<PaidAndCollected/>} />
                    <Route path="/approve-application" element={<ApproveApplication/>} />
                    <Route path="/manage-contracts" element={<ManageContracts/>} />
                    <Route path="/manage-contracts-staff" element={<ManageContractsStaff/>} />
                    <Route path="/id-card" element={<IDCARD />} />

                    {/*PROFILE REDIRECT*/}
                    <Route path="/user/account/staff-profile" element={<StaffProfile />} />
                    <Route path="/logout" element={<Logout/>} />

                    <Route path="*" element={<DashboardRoot />} />
                </Routes>
                <Footer/>
            </div>
        </div>
    </>)
}
