import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import ManagePartnersStaffForm from "./manage-partner-staff-form";
import { isPossiblePhoneNumber } from 'libphonenumber-js'

function ManagePartnersStaff(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
    const [partnerList, setPartnerList] = useState([]);
    const header = ["S/N", "Full Name", "NIN", "Gender", "Partner Name", "Action"];
    const [formData, setFormData] = useState({
        StaffID: "",
        FirstName: "",
        PartnerID:"",
        MiddleName: "",
        Surname: "",
        Gender: "",
        PhoneNumber:"",
        NIN: "",
        Status: "",
        Passport: "",
    })

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}baze/partner/partners/all`).then((res) => {
            if (res.data.length > 0) {
                setPartnerList(res.data)
            }
        })
        await axios.post(`${serverLink}baze/partner/staff/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.FirstName} {item.MiddleName} {item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.NIN}</td>
                        <td className="text-xs font-weight-bold">{item.Gender}</td>
                        <td className="text-xs font-weight-bold">{item.PartnerName}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       StaffID: item.StaffID,
                                       FirstName: item.FirstName,
                                       PartnerID: item.PartnerID,
                                       MiddleName: item.MiddleName,
                                       Surname: item.Surname,
                                       Gender: item.Gender,
                                       PhoneNumber: item.PhoneNumber,
                                       NIN: item.NIN,
                                       Status: item.Status,
                                       // Passport: item.Passport,
                                       InsertedBy: item.InsertedBy,
                                       Photo: item.Passport
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    const isPhoneNumberValid = (phoneNumber) => {
        const isValid = isPossiblePhoneNumber(phoneNumber)

        if (isValid) {
            setPhoneNumberMessage('valid');
        } else {
            setPhoneNumberMessage('invalid')
        }
    };
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
        if (e.target.id === "file") {
            if (e.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            } else {
                setFormData({
                    ...formData,
                    Passport: e.target.files[0]
                })
            }
        }

        if (e.target.id === 'PhoneNumber') {
            isPhoneNumberValid(e.target.value);
        }
        if (e.target.id === "PhoneNumber") {
            if (parseInt(e.target.value.length) > 14) {
                setFormData({
                    ...formData,
                    [e.target.id]: "",
                });
                showAlert("Oops! Phone Number cannot be greater than 14 (+234) digit", " 11 digit expected!", "error");
                return false;
            }
            return false
        }
        if (e.target.id === "NIN") {
            if (parseInt(e.target.value.length) > 11) {
                setFormData({
                    ...formData,
                    [e.target.id]: "",
                });
                showAlert("Oops! NIN cannot be greater than 11 digit", " 11 digit expected!", "error");
                return false;
            }
            return false
        }
    }

    const onSubmit = async () => {
        if (formData.FirstName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter FirstName", "error");
            return false;
        }

        if (formData.Surname.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Surname", "error");
            return false;
        }

        if (formData.Gender.trim() === "") {
            showAlert("EMPTY FIELD", "Please select Gender", "error");
            return false;
        }

        if (phoneNumberMessage === "invalid") {
            showAlert('Input Error', 'Invalid Phone Number', 'error');
            return false;
        }
        if (formData.PhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter PhoneNumber", "error");
            return false;
        }

        if (formData.NIN.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter NIN", "error");
            return false;
        }
        if (formData.Status === "") {
            showAlert("EMPTY FIELD", "Please select Status", "error");
            return false;
        }

        // if (formData.Passport === "") {
        //     showAlert("EMPTY FIELD", "Please upload Passport", "error");
        //     return false;
        // }

        if (formData.StaffID === "") {
            let sendData = {
                ...formData,
                InsertedBy: `${props.loginData?.Username}`,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}baze/partner/staff/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.Passport !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', result.data.entry_id)
                            dt.append('File', formData.Passport)
                            axios.patch(`${serverLink}baze/partner/uploadPhoto`, dt).then((res) => {
                            })
                        }
                        toast.success("Partner Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            StaffID: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            NIN: "",
                            Status: "",
                            Passport: "",
                        });

                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("warning", "Record with this NIN or Phone already exist", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            setIsFormLoading(true);
            let sendData = {
                ...formData,
                // InsertedBy: `${props.loginData?.Username}`,
            }
            await axios
                .patch(`${serverLink}baze/partner/staff/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.Passport !== "") {
                            const dt = new FormData()
                            dt.append('entry_id', formData.StaffID)
                            dt.append('File', formData.Passport)
                            axios.patch(`${serverLink}baze/partner/uploadPhoto`, dt).then((res) => {
                            })
                        }
                        toast.success("Partner Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            StaffID: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            NIN: "",
                            Status: "",
                            Passport: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Partners Staff
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               StaffID: "",
                                               FirstName: "",
                                               MiddleName: "",
                                               PhoneNumber:"",
                                               Surname: "",
                                               Gender: "",
                                               NIN: "",
                                               Status: "",
                                               Passport: "",
                                               PartnerID:"",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Partner Staff
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Partner Staff(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Partner(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManagePartnersStaffForm
                    formData={formData}
                    setFormData={setFormData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    phoneNumberMessage={phoneNumberMessage}
                    partnerList={partnerList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManagePartnersStaff);
