import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import ManagePartnersForm from "./manage-partners-form";
import {isPossiblePhoneNumber} from "libphonenumber-js";

function ManagePartners(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
    const [error, setError] = useState(null);
    const header = ["S/N", "Name", "Email address", "Phone Number", "CAC NO", "Action"];
    const [formData, setFormData] = useState({
        PartnerID: "",
        Name: "",
        EmailAddress: "",
        PhoneNumber: "",
        Address: "",
        CAC: "",
    })

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}baze/partner/partners/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Name}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.CAC}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PartnerID: item.PartnerID,
                                       Name: item.Name,
                                       EmailAddress: item.EmailAddress,
                                       PhoneNumber: item.PhoneNumber,
                                       Address: item.Address,
                                       CAC: item.CAC,
                                       InsertedBy: item.InsertedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
        if (e.target.id === 'EmailAddress') {
            const result = isValidEmail(e.target.value);

            if (result === null) {
                setError('invalid');
            } else {
                setError('valid');
            }
        }
        if (e.target.id === 'PhoneNumber') {
            isPhoneNumberValid(e.target.value);
        }
        if (e.target.id === "PhoneNumber") {
            if (parseInt(e.target.value.length) > 14) {
                setFormData({
                    ...formData,
                    [e.target.id]: "",
                });
                showAlert("Oops! Phone Number cannot be greater than 14 (+234) digit", " 11 digit expected!", "error");
                return false;
            }
            return false
        }
    }
    const isPhoneNumberValid = (phoneNumber) => {
        const isValid = isPossiblePhoneNumber(phoneNumber)

        if (isValid) {
            setPhoneNumberMessage('valid');
        } else {
            setPhoneNumberMessage('invalid')
        }
    };
    const isValidEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Address: event,
        });
    }

    const onSubmit = async () => {
        if (formData.Name.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter partner name", "error");
            return false;
        }

        if (formData.EmailAddress.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }

        if (formData.PhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Phone Number", "error");
            return false;
        }

        if (formData.Address.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Address", "error");
            return false;
        }

        if (formData.CAC.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter CAC", "error");
            return false;
        }

        if (formData.PartnerID === "") {
            let sendData = {
                ...formData,
                InsertedBy: `${props.loginData?.Username}`,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}baze/partner/partner/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Partner Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            PartnerID: "",
                            Name: "",
                            EmailAddress: "",
                            PhoneNumber: "",
                            Address: "",
                            CAC: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            setIsFormLoading(true);
            let sendData = {
                ...formData,
                // InsertedBy: `${props.loginData?.Username}`,
            }
            await axios
                .patch(`${serverLink}baze/partner/partner/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Partner Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            PartnerID: "",
                            Name: "",
                            EmailAddress: "",
                            PhoneNumber: "",
                            Address: "",
                            CAC: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Partners
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               PartnerID: "",
                                               Name: "",
                                               EmailAddress: "",
                                               PhoneNumber: "",
                                               Address: "",
                                               CAC: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Partner
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Partners(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Partner(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManagePartnersForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    descriptionHandler={descriptionHandler}
                    phoneNumberMessage={phoneNumberMessage}
                    error = {error}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManagePartners);
