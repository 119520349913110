import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../resources/url";
import Skeleton from "../common/skeleton/skeleton";
import { MaleAvatar, WomanAvatar } from "../../resources/constants";
import { status_type_chart_data, user_type_chart_data } from "./chart-data";
import ReactApexChart from "react-apexcharts";

function Dashboard(props) {
  const user = props.loginData;
  const [IsLoading, setIsLoading] = useState(true);
  const [userTypeChart, setUserTypeChart] = useState([]);
  const [statusTypeChart, setStatusTypeChart] = useState([]);
  const [partner, setPartner] = useState(0);
  const [contact, setContract] = useState(0);
  const [staff, setStaff] = useState(0);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await axios.get(`${serverLink}baze/partner/dashboard/count`).then((res) => {
      setPartner(res.data.partners);
      setContract(res.data.contract);
      setStaff(res.data.staff);
    });

    await axios
      .get(`${serverLink}baze/partner/user-type-trend`)
      .then((res) => {
        if (res.data.length > 0) {
          let dt = user_type_chart_data(res.data);
          setUserTypeChart([dt]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
    await axios
      .get(`${serverLink}baze/partner/status-type-trend`)
      .then((res) => {
        if (res.data.length > 0) {
          let dt = status_type_chart_data(res.data);
          setStatusTypeChart([dt]);
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  return IsLoading ? (
    <Skeleton />
  ) : (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Dashboard</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">
                    <span
                      className="avatar avatar-lg img-thumbnail rounded"
                      style={{ backgroundImage: `url(${MaleAvatar})` }}
                    />
                  </div>
                  <div className="col">
                    <h2 className="fw-bold">{user?.Username}</h2>
                    <div className="list-inline-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon me-2 text-muted"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                        <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                        <path d="M12 12l0 .01" />
                        <path d="M3 13a20 20 0 0 0 18 0" />
                      </svg>
                      <span className="text-reset">
                        {user?.Role === "1" ? "Admin" : "User"}
                      </span>
                    </div>
                    <div className="list-inline-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon me-2 text-muted"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                      </svg>
                      <span className="text-reset">
                        {user?.Status === "1" ? "Active" : "In-Active"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Partners</div>
                    <div className="ms-auto lh-1"></div>
                  </div>
                  <div className="h1 mb-3">{partner}</div>
                  <div className="d-flex mb-2">
                    <div>Total Partners</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Contract</div>
                  </div>
                  <div className="h1  mb-3">{contact}</div>
                  <div>Total Contract</div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Staff </div>
                  </div>
                  <div className="h1  mb-3">{staff}</div>
                  <div>Total Staff</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Trend</h3>
                  <div className="row row-cards">
                    <div className="col-sm-6 col-lg-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <h3 className="card-title">User type trend</h3>
                          {userTypeChart.length > 0 && (
                            <div id="chart">
                              <ReactApexChart
                                options={userTypeChart[0].options}
                                series={userTypeChart[0].series}
                                type="donut"
                                width={"100%"}
                                height={300}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <div className="card card-sm">
                        <div className="card-body">
                          <h3 className="card-title">User status trend</h3>
                          {statusTypeChart.length > 0 && (
                            <div id="chart">
                              <ReactApexChart
                                options={statusTypeChart[0].options}
                                series={statusTypeChart[0].series}
                                type="donut"
                                width={"100%"}
                                height={300}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginDetails,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
