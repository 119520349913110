import React, { useState } from "react";
import "../id-card/id-card.css";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { serverLink } from "../../resources/url";
import QRCode from "react-qr-code";
import "./style.css";

export default function IdCardView(props) {
  const [printPage, setprintPage] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onPrintPage = () => {
    setprintPage(true);
    setTimeout(() => {
      handlePrint();
      setprintPage(false);
    }, 1000);
  };

  return (
    <>
      <div
        className="modal modal-blur fade"
        id={props.id ?? "modal-report"}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Staff ID-CARD</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div
              className="modal-body"
              ref={componentRef}
              style={{ marginLeft: "-90px" }}
            >
              <div className="parent">
                <div className="app-container">
                  <div className="main-content">
                    <div className="sidebar">
                      <img src="./side_bar.png" alt="siderbar" />
                    </div>
                    <div className="content">
                      <div className="background">
                        <img src="./background.png" alt="background" />
                      </div>
                      <div className=" foreground">
                        <div className="foreground-content">
                          <h1 className="foreground-title text-capitalize">
                            {props.formData.PartnerName}
                          </h1>
                          <div className="foreground-id-card">
                            <img
                              src={`${serverLink}public/uploads/partner_uploads/staff/${props.formData.Passport}`}
                              alt="backgorund "
                            />
                          </div>
                          <div className="foreground-info">
                            <div className="foreground-info-item">
                              <h2 className="text-center">
                                <b>{props.formData.StaffName}</b>
                              </h2>
                              {/*<h3><b>Staff</b></h3>*/}
                            </div>

                            <div className="foreground-info-item">
                              <h3>
                                <span>COMPANY: </span>{" "}
                                <span className="text-capitalize">
                                  {props.formData.PartnerName}
                                </span>
                              </h3>
                              <h3>
                                <span>STAFFID:</span>
                                {props.formData.StaffID}
                              </h3>
                              <h2>EXPIRES ON: {props.formData.Expiry}</h2>
                            </div>
                            <div className="d-flex justify-content-end align-content-end">
                              <QRCode
                                size={100}
                                value={`Staff ID: ` + props.formData.StaffID}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="back-id"
                  style={{ marginTop: "10px", position: "relative" }}
                >
                  <img src="back-id.png" alt="background" />
                  <div
                    style={{
                      position: "absolute",
                      top: "17%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      height: "200px",
                      width: "300px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "1px solid #000",
                    }}
                  >
                    <img
                      src={`${serverLink}public/uploads/partner_uploads/staff/${props.formData.Passport}`}
                      alt="centered"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a
                href="#"
                id="closeModal"
                className="btn btn-link link-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </a>
              {props.IsFormLoading ? (
                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    />{" "}
                    Please wait...
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={onPrintPage}
                  className="btn btn-primary ms-auto"
                >
                  <span className=" d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    Print
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
