import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";
import DataTable from "../common/data-table/data-table";
import {formatDate, formatDateAndTime} from "../../resources/constants";
import ManageContractsStaffForm from "./manage-contracts-staff-form";
import IdCardView from "./id-card-view";

function ManageContractsStaff(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const header = ["S/N", "Staff Name","Partner Name", "Contract Name", "Status", "StartDate", "EndDate",  "Action"];
    const [formData, setFormData] = useState({
        AllocationID: "",
        ContractID: "",
        PartnerID: "",
        StaffID: "",
        StartDate: "",
        EndDate: "",
        Status: "",
    })
    const [partnerList, setPartnerList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [filteredStaffList, setFilteredStaffList] = useState([]);
    const [filteredContractList, setFilteredContractList] = useState([]);


    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {

        await axios.get(`${serverLink}baze/partner/partners/all`).then((res) => {
            if (res.data.length > 0) {
                setPartnerList(res.data)
            }
        })
        await axios.post(`${serverLink}baze/partner/staff/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStaffList(result.data);
                }
                setIsLoading(false)
            })

        await axios.get(`${serverLink}baze/partner/contracts/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setContractList(result.data);
                }
                setIsLoading(false)
            })

        await axios.get(`${serverLink}baze/partner/allocation/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setItemsList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return itemsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StaffName}</td>
                        <td className="text-xs font-weight-bold">{item.PartnerName}</td>
                        <td className="text-xs font-weight-bold">{item.ContractName}</td>
                        <td className="text-xs font-weight-bold">{item.Status === 1 ? 'Active' : 'InActive'}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.StartDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.EndDate, 'date')}</td>
                        <td className="text-xs font-weight-bold d-flex gap-1">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PartnerID: item.PartnerID,
                                       AllocationID: item.AllocationID,
                                       ContractID: item.ContractID,                                     
                                       StaffID: item.StaffID,
                                       Status: item.Status,
                                       StartDate: item.StartDate,
                                       EndDate: item.EndDate,
                                       InsertedBy: item.InsertedBy,
                                   });
                                   setFilteredStaffList(staffList.filter(x => x.PartnerID === parseInt(item.PartnerID)));
                                   setFilteredContractList(contractList.filter(x => x.PartnerID === parseInt(item.PartnerID)));
                               }}>
                                Edit
                            </a>
                            <a href="#" className={"btn btn-info btn-sm"} data-bs-toggle="modal" data-bs-target="#id-card"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PartnerID: item.PartnerID,
                                       PartnerName: item.PartnerName,
                                       AllocationID: item.AllocationID,
                                       ContractID: item.ContractID,
                                       StaffID: item.StaffID,
                                       StaffName: item.StaffName,
                                       Status: item.Status,
                                       StartDate: item.StartDate,
                                       EndDate: item.EndDate,
                                       Passport: item.Passport,
                                       Expiry: formatDateAndTime(item.EndDate, 'date'),
                                       InsertedBy: item.InsertedBy,
                                   });

                               }}>
                                Print ID CARD
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit2 = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
        setFilteredStaffList(staffList.filter(x=>x.PartnerID === parseInt(e.target.value)))
        setFilteredContractList(contractList.filter(x=>x.PartnerID === parseInt(e.target.value)))
    }

    const onSubmit = async () => {
        if (formData.PartnerID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select partner", "error");
            return false;
        }
        if (formData.StaffID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select staff", "error");
            return false;
        }
        if (formData.ContractID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select staff", "error");
            return false;
        }
        if (formData.StartDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select start date", "error");
            return false;
        }

        if (formData.EndDate.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select end date", "error");
            return false;
        }

        if (formData.AllocationID === "") {
            let sendData = {
                ...formData,
                InsertedBy: `${props.loginData?.Username}`
            }

            setIsFormLoading(true);
            await axios
                .post(`${serverLink}baze/partner/staff/allocation`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Staff Allocation Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            AllocationID: "",
                            ContractID: "",
                            PartnerID: "",
                            StaffID: "",
                            StartDate: "",
                            EndDate: "",
                            Status: "",
                        });
                    } else if(result.data.message === "exist"){
                        toast.error("Staff already allocated to the select partner and contract ");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                StartDate: formatDate(formData.StartDate),
                EndDate: formatDate(formData.EndDate)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}baze/partner/allocation/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Staff Allocation Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            AllocationID: "",
                            ContractID: "",
                            PartnerID: "",
                            StaffID: "",
                            StartDate: "",
                            EndDate: "",
                            Status: "",
                        });
                    }  else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Contract Staff List
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               AllocationID: "",
                                               ContractID: "",
                                               PartnerID: "",
                                               StaffID: "",
                                               StartDate: "",
                                               EndDate: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Staff
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Contract Staff</h3>
                                                <DataTable header={header} body={showTable()} title="Contract List" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageContractsStaffForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    partnerList={partnerList}
                    filteredStaffList={filteredStaffList}
                    filteredContractList={filteredContractList}
                    onEdit2={onEdit2}
                />
                <IdCardView
                    id={"id-card"}
                    formData={formData}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageContractsStaff);
