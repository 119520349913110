import React from "react";
import {serverLink} from "../../resources/url";
import male_avatar from '../../images/male-avatar.jpg'
import ImageUploader from "./image-uploader";
export default function ManagePartnersStaffForm(props) {

    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Partners Staff Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {props.formData.StaffID !== "" &&
                                <div className="text-center mb-2">
                                    <img src={props.formData.Photo === "" || props.formData.Photo === null ? male_avatar : `${serverLink}public/uploads/partner_uploads/staff/${props.formData.Photo}`} alt="Profile" width={150} height={150} />
                                </div>}
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Partner Name</label>
                                <select className="form-control" name="PartnerID" id="PartnerID" value={props.formData.PartnerID} onChange={props.onEdit}>
                                    <option value="">Select Partner</option>
                                    {
                                        props.partnerList.length > 0 &&
                                        props.partnerList.map((x, i) => {
                                            return <option key={i} value={x.PartnerID}>{x.Name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">FirstName</label>
                                <input
                                    name="FirstName"
                                    className="form-control"
                                    id="FirstName"
                                    value={props.formData.FirstName}
                                    onChange={props.onEdit}
                                    placeholder="FirstName"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">MiddleName</label>
                                <input
                                    type="text"
                                    name="MiddleName"
                                    className="form-control"
                                    id="MiddleName"
                                    value={props.formData.MiddleName}
                                    onChange={props.onEdit}
                                    placeholder="MiddleName"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Surname</label>
                                <input
                                    type="text"
                                    name="Surname"
                                    className="form-control"
                                    id="Surname"
                                    value={props.formData.Surname}
                                    onChange={props.onEdit}
                                    placeholder="Surname"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">PhoneNumber</label>
                                <input
                                    type="text"
                                    name="PhoneNumber"
                                    className="form-control"
                                    id="PhoneNumber"
                                    value={props.formData.PhoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="PhoneNumber"
                                />
                                {props.phoneNumberMessage === "invalid" && (
                                    <span style={{ backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px' }}>
                                                                {props.phoneNumberMessage && (
                                                                    <>
                                                                        {props.phoneNumberMessage === "invalid" && "Invalid phone number, it must be in this format: +23408136791100"}
                                                                    </>
                                                                )}
                                                            </span>
                                )}
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">NIN</label>
                                <input
                                    type="number"
                                    name="NIN"
                                    className="form-control"
                                    id="NIN"
                                    value={props.formData.NIN}
                                    onChange={props.onEdit}
                                    placeholder="NIN"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Status</label>
                                <select className="form-control" name="Status" id="Status" value={props.formData.Status} onChange={props.onEdit}>
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">In-Active</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Gender</label>
                                <select className="form-control" name="Gender" id="Gender" value={props.formData.Gender} onChange={props.onEdit}>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="FeMale">FeMale</option>
                                </select>
                            </div>

                            {/*<div className="col-md-12">*/}
                            {/*    <div className="row mb-3">*/}
                            {/*        <div className="col-md-12 col-lg-12">*/}
                            {/*            <label htmlFor="file" className="col-md-4 col-lg-3 col-form-label">Passport </label>*/}
                            {/*            (<small className="text-danger">Max size is 1mb</small>)*/}
                            {/*            <input type="file" accept="image/*" className="form-control" id="file" onChange={props.onEdit} />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <ImageUploader formData={props.formData} setFormData={props.setFormData} />

                        </div>


                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span className="d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
