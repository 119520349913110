import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../authentication/login";
import PageNotFound from "../404/page-not-found";
import Logout from "../authentication/logout";
import Top from "../common/header/top";

export default function PublicRoutes() {
  return (
    <div className="wrapper">
      <Top />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}
