import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import Skeleton from "../common/skeleton/skeleton";
import Dashboard from "./dashboard";
import StaffDashboard from "./StaffDashboard";
import StudentDashboard from "./student-dashboard";

function DashboardRoot(props) {

    const [IsLoading, setIsLoading] = useState(true)
    const [userType, setUserType] = useState(props.loginData ?? []);
    return (
        userType.length < 1 ?
            <Skeleton/>
            :
              <Dashboard/>

    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(DashboardRoot);
